.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

.embla_4x3 {
  width: 100%;
  aspect-ratio: 4/3;
}
.embla_3x4 {
  width: 100%;
  aspect-ratio: 3/4;
}
.embla_a3_portrait {
  width: 100%;
  aspect-ratio: 297/419;
  border: solid 1px #bbb;
}
.embla_a3_landscape {
  width: 100%;
  aspect-ratio: 420/297;
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__img {
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 2.5rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0 1rem 0 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1rem;
  height: 1rem;
  z-index: 1;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 80%;
  height: 80%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((1.4rem - 0.8rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.4);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.6rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.05rem rgba(0, 0, 0, 0.2);
  width: 1.1rem;
  height: 0.6rem;
  border-radius: 0%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.05rem rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
}
.embla__lazy-load {
  position: relative;
  height: 100%;
}
.embla__lazy-load__spinner {
  border: 0.4rem solid rgba(var(--text-high-contrast-rgb-value), 0.2);
  border-left: 0.4rem solid var(--text-high-contrast);
  font-size: 1rem;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-indent: -9999em;
  animation: loading 1.1s infinite linear;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}
.embla__lazy-load__spinner:after {
  border-radius: inherit;
  width: 5rem;
  height: 5rem;
}
.embla__lazy-load__img {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.embla__lazy-load--has-loaded .embla__lazy-load__img {
  opacity: 1;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
