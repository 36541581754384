@import "embla.css";

a {
  text-decoration: underline;
}

a::after {
  content: "";
  background: url("/public/img/link.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.2em; /* spacing*/
  padding-right: 0.75em; /* sizing */
}
